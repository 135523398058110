import { cn } from "@/lib/cn";
import { cva } from "class-variance-authority";
import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "danger"
  | "ternadia"
  | "outlined"
  | "transparent"
  | "transparentLink"
  | "outlinedDanger";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  type: "submit" | "button" | "reset";
  variant?: ButtonVariant;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  children?: ReactNode;
};

// If you want to pass customClassNames then you can use clsx
// with clsx you can send the variants such as
// clsx('text-white bg-ternadia-600', { 'bg-gray-500': variableIfTrue })

export const Button = ({
  type,
  variant,
  size,
  fullWidth,
  disabled,
  className,
  children,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={twMerge(
        cn(buttonVariants({ variant, size, fullWidth, disabled })),
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

const buttonVariants = cva(
  "flex flex-row gap-1 items-center justify-center text-white bg-ternadia-600 hover:bg-ternadia-700 focus:ring-4 focus:outline-none focus:ring-ternadia-300 font-medium rounded-md text-sm px-4 py-2 text-center dark:bg-ternadia-600 dark:hover:bg-ternadia-700 dark:focus:ring-ternadia-800 shadow",
  {
    variants: {
      size: {
        sm: "text-xs px-1 py-1",
        md: "text-sm px-4 py-2",
        lg: "text-lg px-6 py-3",
      },
      fullWidth: {
        true: "w-full",
      },
      variant: {
        primary:
          "text-white bg-primary transition-all hover:bg-primaryHover focus:ring-2 focus:outline-none focus:ring-active font-medium rounded-lg text-center dark:text-primary dark:bg-primaryBgLight dark:hover:bg-primaryBgLight dark:focus:primaryBgLight active:opacity-[0.85] ",
        outlined:
          "text-primary border border-slate-400 bg-surface hover:bg-gray-50 focus:ring-2 focus:outline-none focus:ring-active font-medium rounded-lg text-center dark:bg-surface dark:hover:bg-surface dark:focus:ring-surface",
        ternadia:
          "text-white bg-ternadia-600 hover:bg-ternadia-700 focus:ring-4 focus:outline-none focus:ring-ternadia-300 font-medium rounded-lg text-center dark:bg-ternadia-600 dark:hover:bg-ternadia-700 dark:focus:ring-ternadia-800",
        secondary:
          "text-white bg-ternadiaLinks-600 hover:bg-ternadiaLinks-700 focus:ring-4 focus:outline-none focus:ring-ternadiaLinks-300 font-medium rounded-lg text-center dark:bg-ternadiaLinks-600 dark:hover:bg-ternadiaLinks-700 dark:focus:ring-ternadiaLinks-800",
        danger:
          "text-white bg-danger-600 hover:bg-danger-700 focus:ring-4 focus:outline-none focus:ring-danger-300 font-medium rounded-lg text-center dark:bg-danger-600 dark:hover:bg-danger-700 dark:focus:ring-danger-800",
        outlinedDanger:
          "text-danger-700 border border-danger-700 bg-surface hover:bg-gray-50 focus:ring-2 focus:outline-none focus:ring-active font-medium rounded-lg text-center dark:bg-surface dark:hover:bg-surface dark:focus:ring-surface",
        transparent:
          "text-white border-0 bg-transparent shadow-none focus:outline-none font-medium text-center text-primary p-1 hover:bg-transparent",
        transparentLink:
          "text-link border-0 bg-transparent shadow-none focus:outline-none font-medium text-center p-1 hover:bg-transparent",
      },
      disabled: {
        true: "opacity-50 cursor-not-allowed",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  }
);
